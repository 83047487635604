<template lang="pug">
div.container
  div(v-if="$route.params.result === 'success'")
    div.row.justify-content-center
      div.col-sm-8
        h2 Welcome to IGDA Finland!
        p Thank you for supporting our game development community. Your membership is valid immediately and you should have a payment receipt in your email.
        p We'd love to see you in some of our many events so please keep an eye on <a href="https://www.igda.fi/">igda.fi</a> for the latest updates. We also invite you to <a href="https://www.igda.fi/collaborate/">contribute to the community</a> in any way that feels right!
        p Together we can make Finland the best place there is for game development.
        p: i - IGDA Finland Team
  div(v-else)
    b-row(align-h="center")
      div.col-md-8
        b-alert(v-if="$route.params.result === 'cancel'" variant="warning" show).text-center <strong>Payment cancelled.</strong> No money was charged from your card.
    
    div.row.justify-content-center.text-center
      h1 Join IGDA Finland
    div.row.justify-content-center.text-center
      h2 Already a member? You can also <router-link to="/individual-members/get-link">renew!</router-link>
    br/
    br/
    div.row.justify-content-center
      div.col-lg-8
        b-form
          div.row.justify-content-center
            h3 1. Select Membership Type
          div.row.justify-content-center
            b-form-group(label="Membership Type" label-for="type")
              b-select(name="type" v-model="member.membershipType" required)
                option(value="null") Select membership
                option(value="Individual") Individual / 40€
                option(value="Unemployed") Unemployed / 25€
                option(value="Student") Student / 25€
                option(value="Lifetime") Lifetime / 800€

          div(v-if="member.membershipType")
            hr/

            div.row.justify-content-center
              h3 2. Personal Details
            div.row.justify-content-center
              b-form-group.col-sm-4.p-2(label="First Name" label-for="firstName")
                b-input(type="text" name="firstName" v-model="member.firstName" required)
              b-form-group.col-sm-4.p-2(label="Last Name" label-for="lastName")
                b-input(type="text" name="lastName" v-model="member.lastName" required)
            div.row.justify-content-center
              b-form-group.col-sm-4.p-2(label="Email" label-for="email")
                b-input(type="email" name="email" v-model="member.email" @input="resetEmailCheck" @change="checkEmail" required)
              b-form-group.col-sm-4.p-2(label="Place of Residence" label-for="city")
                b-input(type="text" name="city" v-model="member.postOffice")

            hr/

            div.row.justify-content-center
              h3 3. Checkout
            div.row.justify-content-center
              h3 {{member.membershipType}} Membership / {{amount}}€
            div.row.justify-content-center.text-center
              b-alert(show variant="warning" v-if="!emailFree && emailChecked") A member already exists with the email {{member.email}}! Maybe you'd like to <router-link to="/individual-members/get-link">renew</router-link> instead?
            div.row.justify-content-center
              p(v-if="!isFormValid") Fill in all the fields before checkout.
              p(v-if="!emailChecked && isFormValid") Checking email...
              b-row(v-if="emailChecked && emailFree && isFormValid" align-h="center")
                p(v-if="!stripe") Loading payment processor...
                b-button#checkout-button(v-else size="lg" variant="success" @click="openStripe") Go to Payment

</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      member: {
        membershipType: null,
        firstName: '',
        lastName: '',
        email: '',
        postOffice: ''
      },
      newMember: null,
      emailFree: false,
      emailChecked: false,
      globalState,
      stripe: null
    }
  },
  computed: {
    amount: function () {
      if (this.member.membershipType === 'Individual') return 40
      else if (this.member.membershipType === 'Unemployed') return 25
      else if (this.member.membershipType === 'Student') return 25
      else return 800
    },
    stripeProduct: function () {
      if (this.member.membershipType === 'Individual') return this.globalState.isProductionEnv ? 'sku_FYlzwGToZzHlXe' : 'sku_FYm5sQjRUHfKmR'
      else if (this.member.membershipType === 'Unemployed') return this.globalState.isProductionEnv ? 'sku_FYm0CA92DNUEir' : 'sku_FYm6SJlfKpUC9p'
      else if (this.member.membershipType === 'Student') return this.globalState.isProductionEnv ? 'sku_FYm0FcWN8n4ytZ' : 'sku_FYm7o11Ps7O0ui'
      else return this.globalState.isProductionEnv ? 'sku_FYm1rtQM4h186P' : 'sku_FYm4Or2SMp1rED'
    },
    isFormValid: function () {
      let result = true
      if (this.member.firstName === '' ||
        this.member.lastName === '' ||
        this.member.email === '' ||
        this.member.postOffice === '') result = false
      return result
    }
  },
  methods: {
    checkEmail: function () {
      this.axios.post('/api/cards/emailcheck', this.member)
        .then(res => {
          if (res.data === 'free') this.emailFree = true
          else this.emailFree = false
          this.emailChecked = true
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Checking email failed. Please let us know!',
            text: err.response.data,
            duration: -1
          })
        })
    },
    resetEmailCheck: function () {
      this.emailFree = false
      this.emailChecked = false
    },
    checkout: function (res) {
      this.processingPayment = true
      let transaction = { stripeToken: res.token, member: this.member }

      this.axios.post('/api/individual-members/checkout', transaction)
        .then(res => {
          this.newMember = res.data
          this.processingPayment = false
        })
        .catch(err => {
          this.transactionError = err
          this.processingPayment = false
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Payment processing failed',
            duration: -1
          })
        })
    },
    openStripe: function () {
      const baseUrl = this.globalState.isProductionEnv ? 'https://emmi.igda.fi/' : 'http://localhost:8080/'

      this.stripe.redirectToCheckout({
        items: [{
          sku: this.stripeProduct,
          quantity: 1
        }],
        clientReferenceId: JSON.stringify({ member: this.member }),
        customerEmail: this.member.email,
        successUrl: baseUrl + 'individual-members/register/success',
        cancelUrl: baseUrl + 'individual-members/register/cancel'
      })
    }
  },
  mounted: function () {
    this.$loadScript('https://js.stripe.com/v3')
      .then(() => {
        // eslint-disable-next-line no-undef
        this.stripe = Stripe(globalState.isProductionEnv ? 'pk_live_v3hSZ3tcDlldY0UKoRGyIiSa' : 'pk_test_EczHd4sOEuvAGcM2Wn8Tn5Zc')
      })
  }
}
</script>
